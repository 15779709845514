import React, { useState } from 'react';
import { useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSlot } from '../../features/bookingSlice/bookingSlice';
import Loader from '../Shared/Loader';
import { IoTimeSharp } from 'react-icons/io5';

const ServiceField = ({ date }) => {
    const [slot1, setSlot1] = useState('');
    const [slot2, setSlot2] = useState('');
    const [slot3, setSlot3] = useState('');
    const [slot4, setSlot4] = useState('');
    const [slot5, setSlot5] = useState('');
    const [slot6, setSlot6] = useState('');
    const [slot7, setSlot7] = useState('');
    const [slot8, setSlot8] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const availableSlot = timeSlots?.filter(slot => slot?.timeSlot !== slot1 && slot?.timeSlot !== slot2
        && slot?.timeSlot !== slot3 && slot?.timeSlot !== slot4 && slot?.timeSlot !== slot5 && slot?.timeSlot !== slot6
        && slot?.timeSlot !== slot7 && slot?.timeSlot !== slot8);

    const formatedDate=useSelector((state)=>state?.booking?.date);
    const {customer}=useSelector((state)=>state?.booking);
    

    useEffect(() => {
        fetch(`https://servers.thakurgaonexpress.com/mntel/getbookingbyquery?booking_date=${formatedDate}`)
            .then(res => res.json())
            .then(data => {
                const filter1 = data?.filter(booking => booking?.time_slot === '10:00am-11:00am');
                if (filter1.length == 4) {
                    const find1 = filter1?.find(book => book?.time_slot);
                    const slot1 = find1?.time_slot;
                    setSlot1(slot1);
                }
                const filter2 = data?.filter(booking => booking?.time_slot === '11:00am-12:00pm');
                if (filter2.length == 4) {
                    const find2 = filter2?.find(book => book?.time_slot);
                    const slot2 = find2?.time_slot;
                    setSlot2(slot2);
                }
                const filter3 = data?.filter(booking => booking?.time_slot === '12:00pm-01:00pm');
                if (filter3.length == 4) {
                    const find3 = filter3?.find(book => book?.time_slot);
                    const slot3 = find3?.time_slot;
                    setSlot3(slot3);
                }
                const filter4 = data?.filter(booking => booking?.time_slot === '02:00pm-03:00pm');
                if (filter4.length == 4) {
                    const find4 = filter4?.find(book => book?.time_slot);
                    const slot4 = find4?.time_slot;
                    setSlot4(slot4);
                }
                const filter5 = data?.filter(booking => booking?.time_slot === '03:00pm-04:00pm');
                if (filter5.length == 4) {
                    const find5 = filter5?.find(book => book?.time_slot);
                    const slot5 = find5?.time_slot;
                    setSlot5(slot5);
                }
                const filter6 = data?.filter(booking => booking?.time_slot === '04:00pm-05:00pm');
                if (filter6.length == 4) {
                    const find6 = filter6?.find(book => book?.time_slot);
                    const slot6 = find6?.time_slot;
                    setSlot6(slot6);
                }
                const filter7 = data?.filter(booking => booking?.time_slot === '05:00pm-06:00pm');
                if (filter7.length == 4) {
                    const find7 = filter7?.find(book => book?.time_slot);
                    const slot7 = find7?.time_slot;
                    setSlot7(slot7);
                }
                const filter8 = data?.filter(booking => booking?.time_slot === '06:00pm-07:00pm');
                if (filter8.length == 4) {
                    const find8 = filter8?.find(book => book?.time_slot);
                    const slot8 = find8?.time_slot;
                    setSlot8(slot8);
                }
                setLoading(false)
            })
    }, [date]);

    useEffect(() => {
        fetch("https://servers.thakurgaonexpress.com/mntel/gettimeslots")
            .then(res => res.json())
            .then(data => {
                setTimeSlots(data);
                setLoading(false)
            })
    }, [date]);




    const handleAddSlot = (id, slot, date) => {
        navigate(`/times/${id}`);
        const data = { date, slot }
        console.log(date, slot);
        dispatch(getSlot(data));

    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className='mt-20'>
        <h3 className='text-xl text-red-600 text-center my-4'>Hello, {customer?.name}</h3>
        <h4 className='text-lg text-red-600 text-center my-4'>Your Available Time Range On {formatedDate}</h4>    
            <div className='flex justify-center items-center flex-col'>
                <div className='grid grid-cols-2 gap-4'>
                    {
                        availableSlot.map(schudule => <div onClick={()=>handleAddSlot((schudule._id),(schudule.timeSlot),(formatedDate))} className={`shadow-lg p-4 cursor-pointer`}>
                            <button className='btn btn-error uppercase text-white mt-4 mx-auto'><IoTimeSharp/> {schudule?.timeSlot}</button>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceField;